    @use 'vars' as *;
    .t-page {
        background-color: #FFF;
        &.scrolled {
            .intro {
                min-height: 80vh;
                /*
                .intro-content {
                    This was causing the content to jump as the user scrolled down. I'm not sure it is necessary anywhere.
                   min-height: 80vh;
                  
                } */
                &.fallback {
                    min-height: 0px;
                    .intro-content {
                        min-height: 0px;
                    }
                }
            }
        }
        .intro {
            position: relative;
            transition: all .5s ease-in-out;
            // background-color: rgba(43, 35, 15, .7);
            // background: linear-gradient(90deg, rgba(43, 35, 15, .7) 0%, rgba(43, 35, 15, 0.3) 60%, rgba(43, 35, 15, .7) 100%);
            // background-image: url(../assets/page-intro-texture.png);
            // background-blend-mode: multiply;
            // max-height: 100vh;
            &.has-img {
                min-height: 100vh;
                height: 100%;
                .intro-content {
                    color: #FFF;
                }
            }
            .splash {
                height: 100%!important;
                width: 100%;
                position: absolute;
                z-index: -1; //Treat this as a background image
                max-width: none;
                img {
                    height: 100%!important;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .intro-content {
                transition: all .5s ease-in-out;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-top: 200px;
                h1.page-title {
                    color: $yellow;
                    font-size: 3.5em;
                    text-transform: uppercase;
                    &.sml { /* when there is summary */
                        font-size: 18px;
                        margin: 5px 0px;
                    }
                }
                .summary {
                    text-align: center;
                    font-weight: bold;
                    font-size: 3.5em;
                    margin-top: 10px;
                    @media screen and (max-width: 1200px) {
                        font-size: 5vw;
                    }
                    @media screen and (max-width: 560px) {
                        font-size: 24px;
                    }
                }
            }
            .intro-sponsor {
                position: absolute;
                bottom: 25px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                span,
                img {
                    padding: 0 1vw;
                }
                .banner-stage {
                    .banner-name {
                        display: none;
                    }
                    justify-content: space-around;
                    align-items: center;
                    display: flex;
                    width: 100%;
                    height: auto;
                }
            }
        }
        .content-body {
            padding: 7rem 0;
            font-size: 26px;
            background-image: url(../assets/page-content-bg.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-position-y: 400px;
            background-size: 100% 85vh;
            .two-col {
                grid-gap: 4rem;
            }
            @media screen and (max-width: 1200px) {
                font-size: 2vw;
            }
            @media screen and (max-width: 960px) {
                font-size: 2.5vw;
                .two-col {
                    grid-template-columns: 1fr;
                }
            }
            @media screen and (max-width: 560px) {
                font-size: 18px;
                .two-col {
                    grid-gap: 4rem;
                }
            }
        }
    }
    .t-faq {
        .content-body {
            background-image: none;
        }
        .sandy {
            padding: 7% 0;
            padding-top: 5%;
            background-image: url(../assets/page-content-bg.png);
            background-size: cover;
        }
    }