@use 'vars' as *;

/* used by projects and services */
.content-header {
    background-color: #FFF;
    font-weight: bold;
    color: $blue;
    box-shadow: 0px 3px 5px 1px rgb(0 0 0 / 16%);
    h1 {
        font-size: 74px;
        text-transform: lowercase;
        @media screen and (max-width: 960px) {
            font-size: 7vw;
        }
        @media screen and (max-width: 560px) {
            font-size: 40px;
        }
    }
}
.t-project {
    background-color: $dirty-white;
    // background: linear-gradient(180deg, $dirty-white 74%, rgba(255, 255, 255, 1) 74%);
    &.scrolled {
        .intro {
            min-height: 80vh;
            .intro-content {
                min-height: 80vh;
                .page-content {
                    margin-bottom: -5rem;
                }
            }
            @media screen and (max-width: 1250px) {
                min-height: 0px;
                .intro-content {
                    min-height: 0px;
                    .page-content {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .intro {
        position: relative;
        min-height: 100vh;
        background-size: cover;
        transition: all .5s ease-in-out;
        @media screen and (max-width: 1250px){
            min-height: 0px;
        }
        .splash {
            height: 100%!important;
            width: 100%;
            position: absolute;
            z-index: -1; //Treat this as a background image
            max-width: none;
            img {
                height: 100%!important;
                width: 100%;
                object-fit: cover;
            }
            @media screen and (max-width: 1250px){
                position: relative;
                height: auto;
                padding-top: 50%;
            }
        }
        .intro-content {
            min-height: 100vh;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            transition: all .5s ease-in-out;
            @media screen and (max-width: 1250px){
                min-height: 0px;
                height: auto;
            }
            .page-content {
                font-size: 20px;
                background-color: $blue;
                border-top-left-radius: 40px;
                height: fit-content;
                padding: 2.5rem 4rem;
                width: 1200px;
                transition: all .5s ease-in-out;
                @media screen and (max-width: 1250px){
                    margin-top: -15%;
                }
                @media screen and (max-width: 560px) {
                    border-top-left-radius: 0;
                }
                .three-col {
                    grid-gap: 3rem;
                    @media screen and (max-width: 560px) {
                        grid-template-columns: 1fr;
                    }
                }
                .page-title {
                    color: #FFF;
                    font-weight: bold;
                    font-size: 74px;
                    text-transform: lowercase;
                    margin-bottom: 2rem;
                    @media screen and (max-width: 960px) {
                        font-size: 7vw;
                    }
                    @media screen and (max-width: 560px) {
                        font-size: 28px;
                    }
                }
                .details .title {
                    color: $yellow;
                    font-weight: 600;
                    font-size: 26px;
                    border-bottom: 1px solid #FFF;
                    @media screen and (max-width: 960px) {
                        font-size: 22px;
                    }
                    @media screen and (max-width: 560px) {
                        font-size: 18px;
                    }
                }
                .details .data {
                    padding: 1rem 0;
                    color: #FFF;
                    font-size: 18px;
                    border-bottom: 7px;
                    font-weight: 300;
                }
            }
        }
    }

    .content-subheader {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
        padding: 4rem 2rem;
        h5 {
            font-size: 26px;
            font-weight: 300;
            @media screen and (max-width: 560px) {
                font-size: 22px;
            }
        }
        h6 {
            font-size: 18px;
            font-weight: normal;
            text-transform: uppercase;
            @media screen and (max-width: 560px) {
                font-size: 18px;
            }
        }
    }
    .content-body {
        padding-bottom: 7rem;
    }
    .cards.two-col {
        @media screen and (max-width: 560px) {
            grid-template-columns: 1fr
        }
    }
    .card {
        min-height: 200px;
        background-color: #FFF;
        overflow: hidden;
        border-top-left-radius: 40px;
        border-bottom: 7px solid $yellow;
        text-align: center;
        .slim {
            img {
                width: 80%;
                object-fit: contain;
            }
        }
        img {
            width: 100%;
            height: 383px!important;
            object-fit: cover;
            object-position: center;
            margin: 0 auto;
            @media screen and (max-width: 960px) {
                height: 50% !important;
            }
            // @media screen and (max-width: 560px) {
            //     height: 60vw !important;
            // }
        }
        .card-body {
            padding: 1.5rem 3rem;
            text-align: left;
            .subtitle {
                text-transform: uppercase;
                margin-bottom: .5rem;
                font-size: 18px;
                color: $yellow;
            }
            .title {
                text-transform: uppercase;
                margin-bottom: 1rem;
                font-size: 32px;
                font-weight: bold;
                @media screen and (max-width: 560px) {
                    font-size: 26px;
                }
            }
            .summary {
                font-size: 16px;
                margin-bottom: 2rem;
                font-weight: 300;
            }
            a {
                display: inline-block;
                font-weight: bold;
            }
        }
    }
    .project-item {
        padding-top: 7rem;
        .subtitle {
            font-size: 18px;
            font-weight: normal;
            text-transform: uppercase;
        }
        .content-body {
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: .8fr 1.2fr;
            padding-bottom: 0;
            @media screen and (max-width: 560px) {
                grid-template-columns: 1fr;
            }
            .summary {
                h5 {
                    margin: 0;
                    font-size: 26px;
                    font-weight: 300;
                }
            }
            .main {
                font-size: 18px;
                padding-bottom: 4rem;
                p:first-child {
                    margin-top: 0;
                }
            }
        }
    }
    .banners {
        .banner-stage {
            display: flex;
            margin: 5rem 0;
            @media screen and (max-width: 800px) {
                flex-direction: column;
            }
            .banner {
                .banner-name {
                    display: none;
                }
                .banner-content {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-gap: 1rem 2rem;
                    padding-right: 4rem;
                    padding-bottom: 1rem;
                    @media screen and (max-width: 800px) {
                        grid-gap: 0 2rem;
                    }
                    .banner-title {
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 26px;
                        color: $blue;
                    }
                    .banner-caption {
                        grid-column: 2;
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
    .imagedivider {
        img {
            width: 100%;
            height: 540px!important;
            object-fit: cover;
            @media screen and (max-width: 960px) {
                height: 50vw!important;
            }
        }
    }
    .additional-section {
        display: flex;
        width: 100%;
        margin-right: 2vw;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            img {
                margin-bottom: 2rem;
            }
        }
        img {
            width: 750px;
            @media only screen and (max-width: 1200px) {
                max-width: 100%;
            }
        }
        .additional-content {
            padding: 0 2rem;
            &.contentwrap {
                max-width: 900px;
                margin-left: 0;
                & p:first-child {
                    margin-top: 0;
                }
            }
        }
        .faq {
            margin: 2rem 0;
            &>ul {
                &>li {
                    background-color: #fff;
                    margin: 10px 0;
                    padding: 1.5rem 3rem;
                    border-radius: 10px;
                    cursor: pointer;
                    font-weight: 600;
                    position: relative;
                    font-size: 16px;
                    // border: 1px solid $black;
                    @media screen and (max-width: 560px) {
                        padding: 1.5rem;
                        padding-right: 3rem;
                    }
                    &::after {
                        content: url(../assets/x.svg);
                        position: absolute;
                        top: 18px;
                        right: 1.5rem;
                        transform: rotate(45deg);
                        transition: all .25s ease-in-out;
                    }
                    &>ul {
                        max-height: 0;
                        overflow: hidden;
                        transition: all .5s ease-in-out;
                        // padding: 2rem 0;
                        box-sizing: border-box;
                        font-weight: 300;
                        font-size: 16px;
                    }
                    &.active {
                        cursor: default;
                        &::after {
                            transform: rotate(0deg);
                        }
                        &>ul {
                            max-height: 1000px;
                        }
                    }
                }
            }
        }
    }
    .two-col {
        grid-gap: 3rem 2rem;
    }
    .gallery-section {
        padding: 7rem 0;
        .gallery-buttons {
            display: flex;
            justify-content: space-between;
            button {
                color: $blue;
                font-weight: 600;
                border: none;
                background: transparent;
                font-size: 26px;
                @media screen and (max-width: 560px) {
                    font-size: 22px;
                    white-space: nowrap;
                }
            }
            .slick-prev {
                &::before {
                    content: url('../assets/left.svg');
                    margin-right: 1rem;
                    color: $yellow;
                    vertical-align: middle;
                }
            }
            .slick-next {
                &::after {
                    content: url('../assets/right.svg');
                    margin-left: 1rem;
                    color: $yellow;
                    vertical-align: middle;
                }
            }
        }
        .kmsgallery {
            .kmsgallery-thumbnail-image {
                display: inline-block;
                height: 500px;
                width: 30vw;
            }
        }
    }
}