@use 'vars' as *;
.t-service {
    background-color: $dirty-white;
    &.scrolled {
        .intro {
            min-height: 80vh;
            .intro-content {
                min-height: 80vh;
            }
        }
    }
    .intro {
        position: relative;
        min-height: 100vh;
        transition: all .5s ease-in-out;
        background-size: cover;
        .splash {
            height: 100%!important;
            width: 100%;
            position: absolute;
            z-index: -1; //Treat this as a background image
            max-width: none;
            img {
                height: 100%!important;
                width: 100%;
                object-fit: cover;
            }
        }
        .intro-content {
            min-height: 100vh;
            height: 100%;
            align-items: flex-end;
            padding: 0;
            transition: all .5s ease-in-out;
            .page-content {
                font-size: 20px;
                background-color: $yellow;
                border-top-left-radius: 40px;
                grid-column: 2;
                height: fit-content;
                padding: 2rem;
            }
            @media screen and (max-width:560px) {
                grid-template-columns: .3fr 1.7fr;
            }
        }
        .intro-sponsor {
            position: absolute;
            bottom: 25px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            span,
            img {
                padding: 0 1vw;
            }
            .banner-stage {
                .banner-name {
                    display: none;
                }
                justify-content: space-around;
                align-items: center;
                display: flex;
                width: 100%;
                height: auto;
            }
        }
    }
    /* content header in projects */
    .content-body {
        position: relative;
        padding: 4% 0;
        font-size: 26px;
        &:before {
            content:"";
            display: block;
            position: absolute;
            left: 0px;
            bottom: 14%;
            height: 46%;
            width: 100%;
            background-image: url(../assets/page-content-bg.png);
            background-size: cover;
            background-blend-mode: multiply;
            background-color: #FAf2e9;
            @media screen and (max-width: 1200px){
                bottom: 0px;
                height: 60%;
            }
        }
        .service-item {
            position: relative;
            text-align: right;
            /* text should control height */
            .content-wrapper {
                position: relative;
                padding-top: 5%;
            }
            .contentwrap {
                height: 100%;
                padding-bottom: 0;
                display: flex;
                align-items: flex-end;
                .content {
                    transition: all 1s ease-in-out;
                    background-image: url(../assets/faq-texture.png);
                    background-blend-mode: multiply;
                    background-color: $blue;
                    width: 590px;
                    margin-top: 10rem;
                    color: #FFF;
                    border-top-left-radius: 80px;
                    border-top-left-radius: 13%;
                    padding: 3rem;
                    min-height: 590px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    z-index: 5;
                    position: relative;
                    .title {
                        font-size: 36px;
                        font-weight: 600;
                    }
                    .content-text {
                        font-weight: 300;
                    }
                }
            }
            .graphic { /* image should be absolute, not content */
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 1200px;
                min-width: 60%;
                height: 100%;
                background-color: gray;
                border-top-left-radius: 200px;
                border-top-left-radius: 19%;
                overflow: hidden; /* chop the image corner */
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: 100%!important;
                    width: 100%;
                    object-fit: cover;
                }
            }
            &.left {
                text-align: left;
                .content {
                    color: #000;
                    margin-left: auto;
                    background-color: $yellow;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 80px;
                    border-top-right-radius: 13%;
                }
                .graphic {
                    right: auto;
                    left: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 200px;
                    border-top-right-radius: 19%;
                }
                .three-col {
                    direction: rtl;
                }
            }
            @media screen and (max-width: 1200px) {
                .graphic {
                    position: relative;
                    display: block;
                    width: 100%;
                    padding-top: 60%;
                }
                .contentwrap .content {
                    min-height: 0!important;
                    margin-top: -25%;
                }
            }
        }
    }
    .cards {
        min-height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: gray;
        &.overlay {
            padding-left: 55px; /* for plus */
            &::after {
                content: url(../assets/plus.svg);
                filter: invert(1);
                font-size: 20px;
                position: absolute;
                left: 10px;
                bottom: 5px;
                color: #fff;
                transition: all .5s ease-in-out;
            }
            &:hover {
                &::before {
                    opacity: 0;
                }
                .overlay {
                    opacity: 1;
                }
                &::after {
                    transform: rotate(45deg);
                    opacity: 0;
                }
                .title {
                    opacity: 0;
                }
            }
        }
        &:hover {
            &::before {
                opacity: 0;
            }
        }
        &::before { /* darken */
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            background-color: rgba(0,0,0,0.2);
            mix-blend-mode: multiply;
            opacity: 1;
            transition: opacity 0.5s ease;
            z-index: 0;
        }
        .title {
            position: relative;
            text-transform: uppercase;
            margin-bottom: 1rem;
            color: white;
            font-size: 18px;
            transition: all .5s ease-in-out;
            z-index: 1;
            text-align: center;
        }
        .overlay {
            transition: all .5s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            overflow-y: auto; /* allows vertical scroll if overflow */
            font-size: 16px;
            opacity: 0;
            padding: 1rem;
            box-sizing: border-box;
            background-color: rgba(255, 168, 42, .9);
        }
    }
    .sub-serviceitem {
        margin-top: 2rem;
        margin-bottom: 200px;
        @media screen and (max-width: 1200px){
            margin-top: 0px;
            margin-bottom: 40px;
        }
    }
    .three-col {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        justify-content: end;
        width: 100%;
        box-sizing: border-box;
        @media screen and (max-width: 960px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:560px) {
            grid-template-columns: 1fr;
        }
    }
    .slick-list {
        max-height: 550px;
    }
}