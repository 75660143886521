@use 'vars' as *;
@use 'home';
@use 'page';
@use 'service';
@use 'project';
html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
    scroll-padding-top: 175px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

 ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

 ::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

input,
select,
textarea,
button {
    border-radius: 0;
    /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

button {
    font-family: $font-primary;
}

body {
    padding: 0;
    margin: 0;
    font-size: 12px;
    background-color: $dirty-white;
}

.contentwrap {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 20px;
}

header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border: none;
}

a.logo {
    display: inline-block;
    width: 248px;
    height: auto;
}

a[href=''] {
    pointer-events: none;
}

ul {
    list-style: none;
    padding-left: 0;
}


/** menu generic **/

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

nav li {
    display: inline-block;
    box-sizing: border-box;
}

nav a {
    display: block;
    color: inherit;
    text-decoration: none;
}

header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}


/** form generic **/

form {
    width: 100%;
    box-sizing: border-box;
}

form p {
    position: relative;
    margin: 5px 0;
}

label.float,
input {
    height: 40px;
    line-height: 40px;
}

label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}

input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font-primary;
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
}

textarea {
    min-height: 80px;
}

label.float {
    position: absolute;
    left: 0;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}

.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    font-size: 0.75em;
    margin-top: -10px;
}

form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}


/** utility class **/

.yellow {
    color: $yellow;
}

.blue {
    color: $blue;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-align-items-start {
    align-items: flex-start;
}

.flex-align-items-center {
    align-items: center;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-center {
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

// social icons\
.contact-details-wrapper{
    gap:1rem;
}
// .email-icon{
    
// }
.tel-icon {
    content: url(../assets/tel.svg);
    margin-left: 1rem;
}

.mob-icon {
    content: url(../assets/mob.svg);
    margin-left: 1rem;
}

.contact-data {
    margin-left: 1rem;
}

.kmsgallery-thumbnail-image {
    filter: brightness(.7);
    transition: all .25s ease-in-out;
    &:hover {
        filter: brightness(1);
    }
}


/** Accordion style **/


/** This class will hide all the body be default **/

.accordion {
    &>.panel {
        background-color: #fff;
        margin: 10px 0;
        padding: 1.5rem 3rem;
        border-radius: 10px;
        cursor: pointer;
        &>.header {
            font-weight: 600;
            font-size: 16px;
            position: relative;
            padding-right: 3rem;
            &::after {
                content: url(../assets/x.svg);
                position: absolute;
                top: -5px;
                right: 0;
                transform: rotate(45deg);
                transition: all .25s ease-in-out;
            }
        }
        &>.body {
            max-height: 0;
            overflow: hidden;
            transition: all .5s ease-in-out;
            // padding: 2rem 0;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 300;
        }
        &.active {
            cursor: default;
            &>.header {
                &::after {
                    transform: rotate(0deg);
                }
            }
            &>.body {
                max-height: 1000px;
            }
        }
    }
}

.pages {
    text-align: center;
    padding-top: 30px;
}

.cta {
    height: auto;
    background-image: url(../assets/cta-bg.jpg);
    background-color: rgba(0, 49, 73, .87);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    @media screen and (max-width: 960px) {
        justify-content: flex-start;
    }
    .two-col {
        height: 100%;
        position: relative;
        @media screen and (max-width: 960px) {
            grid-template-columns: 1fr;
            .title-wrapper {
                display: none!important;
            }
            .cta-content-wrapper {
                grid-column: 1!important;
            }
        }
        .title-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .title {
                display: flex;
                flex-direction: column;
                font-size: 92px;
                font-weight: bold;
                text-align: center;
                color: $yellow;
                padding: 1rem;
                @media screen and (max-width: 1200px) {
                    font-size: 5.5vw;
                }
            }
        }
        .cta-content-wrapper {
            grid-column: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 4rem;
            .pretitle {
                font-size: 18px;
            }
            ul {
                padding-left: 0;
            }
            a {
                color: white;
                text-decoration: none;
                font-weight: bold;
                font-size: 76px;
                padding-right: 2rem;
                &:hover {
                    border-bottom: 4px solid $yellow;
                }
            }
            @media screen and (max-width: 1200px) {
                padding: 4rem 2rem;
                a {
                    font-size: 5vw;
                }
            }
            @media screen and (max-width: 560px) {
                a {
                    font-size: 24px;
                }
            }
        }
    }
}

// testimonial style
.testimonials {
    background-image: url(../assets/testimonial-texture.png);
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $yellow;
    padding-top: 6rem;
    padding-bottom: 8rem;
    height: auto;
    .content {
        font-size: 30px;
        @media screen and (max-width: 960px) {
            font-size: 3vw;
        }
        @media screen and (max-width: 560px) {
            font-size: 18px;
        }
    }
    .title {
        font-size: 18px;
        padding-left: 1rem;
    }
    .org {
        font-size: 16px;
        padding-left: 1rem;
    }
}

// contact section style
.contact {
    .header {
        background-color: $blue;
        font-size: 36px;
        color: #fff;
        padding: 2rem 0;
        .site-links {
            align-self: center;
        }
        .contact-details-wrapper{
            font-size: clamp(20px, 3.5vw, 36px);
        }
        @media screen and (max-width: 1220px) {
            .contact-section-wrapper{
                flex-direction: column;
                align-items: center;
              
            }
        }
        
        @media screen and (max-width: 960px) {
            // .title {
            //     display: none;
            // }
            .contact-details-wrapper{
                flex-direction: column;
                text-align: center;
                
            }
        }
        @media screen and (max-width: 600px) {
            .tel-icon, .email-icon{
                display: none;
            }
            .contact-data{
                margin-left: 0px;
            }
            .title {
                display: flex;
                padding-top: 0!important;
                justify-content: center;
            }
            .contentwrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                .flex {
                    padding: 1rem 0;
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }
    }
    .contact-form-wrapper {
        padding-top: 6rem;
        padding-bottom: 6rem;
        background-color: $dirty-white;
        .two-col {
            grid-gap: 0 2rem;
            @media screen and (max-width: 960px) {
                grid-template-columns: 1fr;
            }
        }
        .details {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            color: $blue;
            align-items: flex-start;
            .title {
                font-size: 74px;
                font-weight: bolder;
                border-bottom: 4px solid $yellow;
                padding-bottom: 2rem;
                display: inline;
                @media screen and (max-width: 960px) {
                    font-size: 8vw;
                }
                @media screen and (max-width: 360px) {
                    font-size: 24px;
                }
            }
            .summary {
                padding-top: 2rem;
                font-size: 16px;
                margin-right: 2vw;
                padding-bottom: 2rem;
                font-weight: 300;
            }
            .site-link:first-child {
                margin-left: 0;
            }
        }
    }
}

.flex {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.contact-form {
    input,
    textarea {
        border: 1px solid #DADADA;
        border-radius: 30px;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 1rem 0;
        padding-left: 2rem;
        padding-top: 1.5rem;
    }
    input[type="checkbox"]{
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        margin: 5px;
    }
    .flex {
        h4 {
            padding-left: 5px;
            width: 100%;
            box-sizing: border-box;
        }
    }
    p.checkbox {
        display: inline-block;
        label {
            display: inline-block;
            text-transform: uppercase;
            vertical-align: middle;
            font-size: 14px;
            margin: 5px 10px;
        }
    }
    label.float {
        padding-left: 2rem;
    }
    .switch-field {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        input {
            position: absolute !important;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            width: 1px;
            border: 0;
            overflow: hidden;
        }
        label {
            text-transform: uppercase;
            background-color: transparent;
            color: $yellow;
            border: 1px solid $yellow;
            font-size: 14px;
            line-height: 1;
            text-align: center;
            padding: .75rem 2rem;
            margin-right: -1px;
            transition: all 0.1s ease-in-out;
            cursor: pointer;
            border-radius: 25px;
            margin: 0 .5rem;
            margin-bottom: 1rem;
            transition: all .5s ease-in-out;
            @media screen and (max-width: 560px) {
                width: 100%;
                margin: 0px;
                margin-bottom: 1rem;
            }
            &:hover {
                background-color: $yellow;
                color: #FFF;
                font-weight: bold;
            }
        }
    }
    .switch-field input:checked+label {
        background-color: $yellow;
        color: #fff;
        transition: all .5s ease-in-out;
        &:hover {
            filter: brightness(1.1);
            // color: $blue;
            font-weight: bold;
        }
    }
    .switch-field label:first-of-type {
        margin-left: 0;
    }
    .switch-field label:last-of-type {
        margin-right: 0;
    }
    button.button {
        background-color: $yellow;
        color: $blue;
        border: none;
        border-radius: 25px;
        font-weight: bold;
        display: inline-block;
        width: auto;
        padding: .75rem 2rem;
        margin-bottom: 1rem;
        transition: all .5s ease-in-out;
        @media screen and (max-width: 560px) {
            width: 100%;
        }
        &:hover {
            filter: brightness(1.1);
            color: #FFF;
        }
    }
    .two-col {
        grid-gap: 20px;
    }
    .recaptcha-notice {
        display: inline-block;
        width: 20vw;
        margin-left: 1rem;
        @media screen and (max-width: 960px) {
            width: 100%;
        }
        // @media screen and (max-width: 560px) {
        //     width: 100%;
        // }
    }
    #form-result {
        font-size: 48px;
        color: $blue;
    }
    .form-result-msg {
        font-size: 32px;
        color: $blue;
    }
}

.quote-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: flex-end;
    z-index: 100;
    .quote-card {
        background-color: $dirty-white;
        padding: 3rem 3rem;
        border-radius: 63px 0 0 63px;
        padding-bottom: 7rem;
        transform: translate(640px, 0px);
        transition: all .75s ease-in-out;
        width: fit-content;
        max-width: 640px;
        .title {
            color: $blue;
            border-bottom: 4px solid $yellow;
            font-size: 52px;
            padding-bottom: 1rem;
            display: inline-block;
            margin-bottom: 4rem;
        }
    }
    &.active {
        pointer-events: all;
        .quote-card {
            transform: translate(0px, 0px);
        }
    }
}


/** site links **/

.site-links {
    display: inline-flex;
    align-items: center;
}

.site-link {
    display: inline-flex;
    width: 46px;
    height: 46px;
    margin: 5px;
}


/*============ header ============*/

header.top {
    font-family: $font-primary;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    position: fixed;
    width: 100vw;
    z-index: 10;
    border-bottom: 1px solid #FFF;
}

.header-bar-top {
    color: #ffffff;
    background: rgba(47, 35, 15, .4);
    background-size: cover;
    transition: all 1s ease;
    &.darken {
        background: rgba(47, 35, 15, .8);
    }
    li.has-sub:hover{
        ul{
            display: block;
            li,a{
                display: block;
                width: 100%;
            }
        }
    }
    li.top {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            &>a>.title {
                color: $yellow;
            }
        }
        &.expand-active {
            ul {
                display: flex;
            }
        }
        ul {
            position: absolute;
            top: 75%;
            display: none;
            flex-direction: column;
            line-height: initial;
            text-align: left;
            left: -1.5rem;
            background-color: $dirty-white;
            border-radius: 20px;
            color: $black;
            z-index: 10;
            overflow: hidden;
            li {
                text-transform: lowercase;
                margin: 0;
                a {
                    padding: 1rem 1.5rem;
                    padding-right: 3rem;
                }
            }
            li:hover {
                background-color: $yellow;
                .title {
                    color: $black!important;
                }
            }
          
        }
    }
    ul .expand {
        height: 54px;
        width: 50px;
        cursor: pointer;
    }
    ul .expand i {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        background: url('../assets/menu-drop.svg') no-repeat center center;
        background-size: 30%;
        -webkit-transition: -webkit-transform 500ms ease;
        transition: -webkit-transform 500ms ease;
        transition: transform 500ms ease;
        transition: transform 500ms ease, -webkit-transform 500ms ease;
    }
    .expand-active>.expand i {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

header.top li {
    vertical-align: top;
}

header.top li.active .title {
    color: $yellow;
}

.header-menu {
    line-height: 133px;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 16px;
}

.header-menu>ul {
    text-align: right;
    margin-left: auto;
    padding: 0 1vw;
    display: flex;
}

.header-menu li {
    margin: 20px;
}

.header-bar-bottom {
    background: #333333;
    color: #fff;
    border-top: 1px solid #222222;
    position: relative;
}

.main-menu ul {
    text-align: justify;
    line-height: 0;
}

.main-menu ul::after {
    content: "";
    display: inline-block;
    width: 100%;
    line-height: 0px;
    vertical-align: top;
}

.main-menu li {
    margin: 5px;
    line-height: 1;
}

.main-menu li.depth-0 {
    vertical-align: middle;
}

.main-menu a {
    padding: 15px 0;
}

.main-menu li>ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #f6f6f6;
    color: #082133;
    z-index: 10000;
    text-align: left;
    display: none;
}

.main-menu li:hover>ul {
    display: block;
}

.main-menu li>ul::before,
.main-menu li>ul::after {
    content: "";
    background: #f6f6f6;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100%;
}

.main-menu li>ul::before {
    left: 0;
    padding-left: 50vw;
    margin-left: -50vw;
    background: #f6f6f6;
}

.main-menu li>ul::after {
    right: 0;
    padding-right: 50vw;
    margin-right: -50vw;
    background: #f6f6f6;
}

.main-menu .depth-1 .title {
    font-size: 18px;
}

.main-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}

#mobile-menu {
    display: none;
    margin-right: 2rem;
    margin-left: auto;
    width: 35px;
    height: 30px;
    cursor: pointer;
    z-index: 100;
}

#mobile-menu>span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    // margin: 6px 0;
    transition: all 0.5s ease;
}

.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-8px, 7px);
}

.slide-menu-active #mobile-menu span.three {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
}

.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}


/***** Slide menu ****/

.slide-menu {
    display: none;
}

.slide-menu {
    background: $blue;
    width: 240px;
    z-index: 1000;
    position: fixed;
    height: 100%;
    top: 0;
    right: -240px;
    overflow-y: scroll;
}

.slide-menu-active .slide-menu {
    right: 0px;
}

.site-content-menu-slide {
    overflow-x: hidden;
    position: relative;
    right: 0;
}


/* transition for menu slide */

.slide-menu,
.site-content-menu-slide,
#mobile-menu {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.slide-menu .menu-title {
    padding: 1em;
    color: #fff;
    background: $black;
    margin: 0;
    font-weight: bold;
}

.slide-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.slide-menu ul ul {
    background: $yellow;
}

.slide-menu ul ul ul {
    background: #666;
}

.slide-menu li {
    position: relative;
    display: block;
}

.slide-menu li>ul {
    display: none;
}

.slide-menu li.expand-active>ul {
    display: block;
}

.slide-menu a {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    border-bottom: 1px solid #030b16;
    padding: 18px;
    text-decoration: none;
}

.slide-menu li.has-sub {
    position: relative;
    display: block;
}

.slide-menu li.has-sub>a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px;
    /* space for drop */
}

.slide-menu a:hover {
    background: $dirty-white;
    color: $black;
}

.slide-menu li.has-sub>a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px;
    z-index: 100;
}

.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 54px;
    width: 50px;
    cursor: pointer;
}

.slide-menu ul .expand i {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.expand-active>.expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}


/*============ footer ============*/

footer .footer-bottom {
    background-color: $blue;
    background-size: cover;
    color: #fff;
}

.footer-bottom {
    padding: 4rem 0;
    .logo-wrapper {
        a.logo {
            padding-bottom: 20px;
            border-bottom: 1px solid white;
            width: 100%;
            height: 80px;
            img {
                width: 160px;
                height: 50px!important;
                object-fit: contain;
            }
            @media screen and (max-width: 960px) {
                border-bottom: none;
                padding-bottom: 0px;
            }
            @media screen and (max-width: 560px) {
                text-align: center;
            }
        }
        @media screen and (max-width: 960px) {
            p {
                display: none;
            }
        }
    }
    .menu-wrapper {
        &>ul {
            padding-left: 0;
            margin: 0;
            margin-bottom: 4rem;
            display: flex;
            color: $yellow;
            font-size: 14px;
            font-weight: 300;
            li.top {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                &>a {
                    padding-top: 2rem;
                    border-bottom: 1px solid white;
                    height: 80px;
                }
            }
            li.has-sub {
                ul {
                    // position: absolute;
                    padding: 0;
                    margin-top: 1rem;
                    color: #FFF;
                    list-style: none;
                }
            }
        }
        @media screen and (max-width: 560px) {
            display: none;
        }
    }
    .two-col {
        grid-gap: 0;
        @media screen and (max-width: 960px) {
            grid-template-columns: 1fr;
        }
    }
}

.site-map>ul {
    display: table;
    width: 100%;
}

.site-map>ul>li {
    display: table-cell;
    padding: 20px 0;
}

.site-map li li {
    display: block;
    margin: 10px 0;
}

.site-map a {
    color: inherit;
    text-decoration: none;
    display: block;
    padding-left: 30px;
    position: relative;
    line-height: 30px;
}


/** bykeo ***/

.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.bykeo a {
    color: grey;
    text-decoration: none;
}

.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.bykeo a:hover img {
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/*============ splash ============*/

.main {
    overflow-y: visible;
}


/*  banner */

.banners-home .banner-stage {
    padding-top: 33%;
    position: relative;
}

.banners-home .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-position: center;
    background-size: cover;
}

.banners-home .banner-navigation {
    display: none;
}


/*============ sub page============*/
.splash {
    background-color: gray;
}


/*============ Contact ============*/

.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 48%;
    box-sizing: border-box;
}

.contact-details.has-map {
    padding-right: 10px;
}

.contact-details p {
    margin: 5px 0;
}

.opening-hours {
    display: block;
}

.contact-wrapper form {
    padding-top: 20px;
}

.contact-wrapper form p {
    margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
}

.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#map {
    display: block;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-width: 960px) {
    #mobile-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .header-menu>ul,
    .header-menu .get-a-quote,
    .main-menu {
        display: none;
    }
    .header-menu {
        line-height: 1;
        padding: 2vw;
        a.logo {
            width: 24vw;
            min-width: 105px;
        }
    }
    .slide-menu {
        display: block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }
}

@media only screen and (max-width: 760px) {
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    /** form **/
    .contact-details.has-map,
    .map-wrap {
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}


/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}


/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}