    @use 'vars' as *;
    .t-home {
        &.scrolled {
            .intro {
                min-height: 80vh;
                .intro-content {
                    min-height: 80vh;
                }
            }
        }
        ul {
            list-style: none;
        }
        .intro {
            position: relative;
            min-height: 100vh;
            background-color: rgba(43, 35, 15, .7);
            background: linear-gradient(90deg, rgba(43, 35, 15, .7) 0%, rgba(43, 35, 15, 0.3) 60%, rgba(43, 35, 15, .7) 100%);
            transition: all .5s ease-in-out;
            // max-height: 100vh;
            .splash {
                height: 100%!important;
                width: 100%;
                position: absolute;
                z-index: -1; //Treat this as a background image
                max-width: none;
                img {
                    height: 100%!important;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .intro-content {
                transition: all .5s ease-in-out;
                min-height: 100vh;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFF;
                .intro-2-col {
                    display: grid;
                    grid-template-columns: .8fr 1.2fr;
                    grid-gap: 2rem;
                    font-size: 3vw;
                    .title {
                        font-size: 92px;
                        font-weight: bold;
                        text-align: center;
                        color: $yellow;
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        line-height: 1.25;
                    }
                    .content {
                        font-size: 24px;
                        align-self: center;
                    }
                    @media screen and (max-width: 960px) {
                        grid-template-columns: 1fr;
                        .title {
                            font-size: 12vw;
                        }
                        .content {
                            display: none;
                        }
                    }
                }
            }
            .intro-sponsor {
                position: absolute;
                bottom: 25px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 960px) {
                    display: none;
                }
                span,
                img {
                    padding: 0 1vw;
                }
                .banner-stage {
                    .banner-name {
                        display: none;
                    }
                    justify-content: space-around;
                    align-items: center;
                    display: flex;
                    width: 100%;
                    height: auto;
                }
            }
        }
        .cta-home {
            height: 40vw;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            min-height: 400px;
            position: relative;
            .bg-img{
                position: absolute;
                top:0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .two-col {
                height: 100%;
                position: relative;
                @media screen and (max-width: 560px) {
                    grid-template-columns: 1fr;
                    .cta-content-wrapper {
                        grid-column: 1!important;
                    }
                }
                .cta-content-wrapper {
                    grid-column: 2;
                    background-color: rgba(0, 49, 73, .87);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 4rem;
                    .pretitle {
                        font-size: 18px;
                    }
                    ul {
                        padding-left: 0;
                    }
                    a {
                        color: white;
                        text-decoration: none;
                        font-weight: bold;
                        font-size: 76px;
                        padding-right: 2rem;
                        &:hover {
                            border-bottom: 4px solid $yellow;
                        }
                    }
                    @media screen and (max-width: 1200px) {
                        padding: 4rem 2rem;
                        a {
                            font-size: 5vw;
                        }
                    }
                    @media screen and (max-width: 560px) {
                        a {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .faq {
            background-image: url(../assets/faq-texture.png);
            background-blend-mode: multiply;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: $dirty-white;
            font-size: 18px;
            @media screen and (max-width: 960px) {
                // font-size: 4vw;
                .panel {
                    padding: 1rem 1.5rem;
                }
            }
            @media screen and (max-width: 560px) {
                font-size: 14px;
            }
            .title {
                margin-top: 7rem;
                margin-bottom: 3rem;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 36px;
                color: $yellow;
                text-align: center;
                @media screen and (max-width: 960px) {
                    font-size: 4vw;
                }
                @media screen and (max-width: 560px) {
                    font-size: 24px;
                }
            }
            .faq_footer {
                content: url(../assets/faq-footer.jpg);
                width: 100%;
                height: auto;
                mix-blend-mode: multiply;
            }
        }
        .insta-wrapper{
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }
    #faq {
        .center {
            margin-top: 30px;
        }
    }
    .contact-data {
        a {
            color: inherit;
            text-decoration: none;
        }
    }